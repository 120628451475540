const host = 'https://api.timetailor.com/booking';

export const environment = {
  production: true,
  apiUrl: `${host}/`,
  version: '1617f4582a2133b84798bcc204325b38adde6aee',
  defaultLocationUuid: '700c5ebe-db11-4655-a5ec-ed7b8eb6e9a5',
  stripePublishableKey:
    'pk_live_51O2fkdCs1Y4LA3vDSoWJA9xPdzAMYruyaS1mA3xfqyMSORyhRuCLazQQOkLZmlNmf2R68V7QaQBA5mLsN4tuIu4r00UIKsHTdy',
};
