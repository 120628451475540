import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

@Injectable({
  providedIn: 'root',
})
export class DateTimeUtils {
  public dayFormat = 'YYYY-MM-DD';
  public timeFormat = 'HH:mm:ss';
  public dayTimeFormat = 'YYYY-MM-DD HH:mm:ss';

  getTodayFromTimezone(timezone: string): Date {
    // get current day from the given timezone
    const todayFromTimezone = dayjs().tz(timezone).startOf('day');
    return todayFromTimezone.toDate();
  }

  switchTimezone(date: Date | string, timeZone: string): Date {
    return dayjs.tz(this.formatDayTime(date), timeZone).toDate();
  }

  setTime(date: Date, timeString: string, timeZone: string): Date {
    const dayString = dayjs(date).tz(timeZone).format(this.dayFormat);
    return dayjs.tz(`${dayString} ${timeString}`, timeZone).toDate();
  }

  formatTimeHM(time: string): string {
    if (time) {
      time = time.split(':')[0] + ':' + time.split(':')[1];
    }

    return time;
  }

  addMonths(date: Date, months: number): Date {
    return dayjs(date).add(months, 'month').toDate();
  }

  addDurationToTime(startTime: string, duration: number): string {
    if (!startTime) return startTime;
    let endTime = startTime;

    const startHour = parseInt(startTime.split(':')[0]);
    const startMinute = parseInt(startTime.split(':')[1]);
    const durationHours = Math.floor(duration / 60);
    const durationMinutes = duration % 60;
    let endHour = startHour + durationHours;
    let endMinute = startMinute + durationMinutes;
    if (endMinute >= 60) {
      endHour += 1;
      endMinute -= 60;
    }
    endTime = `${endHour.toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')}`;

    return endTime;
  }

  getEarliestDate(date: Date, timeSlots: string[]): Date {
    if (!timeSlots || timeSlots.length === 0) {
      return date;
    }

    const day = date.toISOString().split('T')[0];
    timeSlots.sort();
    const earliestTime = timeSlots[0].substring(0, 5);

    // Build a proper ISO string in UTC.
    const isoString = `${day}T${earliestTime}:00.000Z`;
    return new Date(isoString);
  }

  getOccupiedTimeSlots(startTime: string, interval: number, serviceDuration: number): string[] {
    const occupiedSlots: string[] = [];
    const endDate = new Date(`2000-01-01 ${this.addDurationToTime(startTime, serviceDuration)}`);
    let crtTime = startTime;

    do {
      occupiedSlots.push(crtTime);
      crtTime = this.addDurationToTime(crtTime, interval);
    } while (new Date(`2000-01-01 ${crtTime}`) < endDate);

    return occupiedSlots;
  }

  filterSlots(startTimes: string[], serviceDuration: number, interval: number): string[] {
    // Helper to convert "HH:mm" to minutes since midnight.
    const minutesFromMidnight = (time: string): number => {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    };

    // Calculate the number of consecutive slots needed.
    const slotsNeeded = Math.ceil(serviceDuration / interval);

    // Convert times to minutes and adjust for times past midnight.
    // For example: "22:00" => 1320, "23:30" => 1410, "00:00" (next day) => 1440, etc.
    const adjustedTimes: number[] = [];
    let previous = -1;
    for (const time of startTimes) {
      let m = minutesFromMidnight(time);
      // If the current time is less than the previous one, it means we've wrapped past midnight.
      if (previous !== -1 && m < previous) {
        m += 1440; // add one day's worth of minutes
      }
      adjustedTimes.push(m);
      previous = m;
    }

    // Now filter the startTimes using the adjusted minutes.
    return startTimes.filter((time, index) => {
      if (index + slotsNeeded - 1 < adjustedTimes.length) {
        const startMinute = adjustedTimes[index];
        // Check if each subsequent slot is exactly interval minutes apart.
        const isValid = adjustedTimes
          .slice(index, index + slotsNeeded)
          .every((t, slotIndex) => t === startMinute + interval * slotIndex);
        return isValid;
      }
      return false;
    });
  }

  formatDay(date: Date | string): string {
    return dayjs(date).format(this.dayFormat);
  }

  formatTime(date: Date | string): string {
    return dayjs(date).format(this.timeFormat);
  }

  formatDayTime(date: Date | string): string {
    return dayjs(date).format(this.dayTimeFormat);
  }
}
