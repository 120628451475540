import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTypographyComponent } from 'ng-zorro-antd/typography';

@Component({
  selector: 'app-appointment-status-info',
  standalone: true,
  templateUrl: './appointment-status-info.component.html',
  imports: [CommonModule, NzTypographyComponent],
})
export class AppointmentStatusInfoComponent implements OnInit {
  @Input() text = '';
  @Input() type: NzTypographyComponent['nzType'] = 'secondary';

  ngOnInit() {}
}
