import { Injectable } from '@angular/core';
import { NzTypographyComponent } from 'ng-zorro-antd/typography';
import { BehaviorSubject, Observable, of } from 'rxjs';
interface NormalModel {
  image?: string;
  title: string;
  isChecked: boolean;
}
export interface Theme extends NormalModel {
  key: 'dark' | 'light';
}
export interface SettingInterface {
  theme: Theme['key']; // Theme modes (dark mode, light mode)
  color: string; // Theme color
  navTitle: string; // Navigation title
  colorWeak: boolean; // Color weakness
  greyTheme: boolean; // Gray mode
  fixedHead: boolean; // Fixed header
  hasTopArea: boolean;
  hasFooterArea: boolean;
  hasNavArea: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private isNightTheme$ = new BehaviorSubject<boolean>(false);
  private isOverModeTheme$ = new BehaviorSubject<boolean>(false);
  private themesMode$ = new BehaviorSubject<SettingInterface>({
    theme: 'light',
    color: '#6800C2', //purple
    navTitle: '',
    colorWeak: false,
    greyTheme: false,
    fixedHead: true,
    hasTopArea: true,
    hasFooterArea: true,
    hasNavArea: true,
  });
  private predefinedThemes = {
    vividCerise: '#DB1581',
    neonPurple: '#bd14d1',
    plum: '#A2007D',
    purple: '#6800C2',
    blue: '#0336FF',
    teal: '#007CA6',
    green: '#009F33',
    burntOrange: '#ab4a08',
    orange: '#DA3A05',
    crimsonRed: '#B20009',
    red: '#e30425',
  };

  // Right sider button
  private submitForm$ = new BehaviorSubject<boolean>(false);
  private cartBtnVisible$ = new BehaviorSubject<boolean>(false);
  private cartBtnLabel$ = new BehaviorSubject<string>('Continue');
  private cartBtnInfoText$ = new BehaviorSubject<string>('');
  private cartBtnInfoType$ = new BehaviorSubject<NzTypographyComponent['nzType']>('secondary');
  private cartBtnDisabled$ = new BehaviorSubject<boolean>(false);

  constructor() {
    this.isNightTheme$.next(window?.matchMedia?.('(prefers-color-scheme:dark)')?.matches ?? false);
  }

  setThemesMode(mode: SettingInterface): void {
    this.themesMode$.next(mode);
  }

  getThemesMode(): Observable<SettingInterface> {
    return this.themesMode$.asObservable();
  }

  setIsNightTheme(isNight: boolean): void {
    this.isNightTheme$.next(isNight);
  }

  getIsNightTheme(): Observable<boolean> {
    // Temporary disable Dark theme
    // return this.isNightTheme$.asObservable();
    return of(false);
  }

  // Whether the theme overlaps the sidebar
  setIsOverMode(isNight: boolean): void {
    this.isOverModeTheme$.next(isNight);
  }

  getIsOverMode(): Observable<boolean> {
    return this.isOverModeTheme$.asObservable();
  }

  setNavTitle(title: string): void {
    this.setThemesMode({ ...this.themesMode$.value, navTitle: title });
  }

  getFormSubmitAction(): Observable<boolean> {
    return this.submitForm$.asObservable();
  }

  setFormSubmitAction(submit: boolean) {
    this.submitForm$.next(submit);
  }

  getCartBtnVisibility(): Observable<boolean> {
    return this.cartBtnVisible$.asObservable();
  }

  setCartBtnVisibility(isVisible: boolean) {
    this.cartBtnVisible$.next(isVisible);
  }

  getCartBtnLabel(): Observable<string> {
    return this.cartBtnLabel$.asObservable();
  }

  setCartBtnLabel(label: string = 'Continue') {
    this.cartBtnLabel$.next(label);
  }

  getCartBtnInfoText(): Observable<string> {
    return this.cartBtnInfoText$.asObservable();
  }

  setCartBtnInfoText(text: string = '') {
    this.cartBtnInfoText$.next(text);
  }

  getCartBtnInfoType(): Observable<NzTypographyComponent['nzType']> {
    return this.cartBtnInfoType$.asObservable();
  }

  setCartBtnInfoType(type: NzTypographyComponent['nzType'] = 'secondary') {
    this.cartBtnInfoType$.next(type);
  }

  getCartBtnDisabled(): Observable<boolean> {
    return this.cartBtnDisabled$.asObservable();
  }

  setCartBtnDisabled(isDisabled: boolean = false) {
    this.cartBtnDisabled$.next(isDisabled);
  }

  getPredefinedThemeColor(name: string): string | null {
    return this.predefinedThemes[name] ? this.predefinedThemes[name] : null;
  }
}
