import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Enable Day.js plugins
dayjs.extend(utc);
dayjs.extend(timezone);

@Pipe({
  name: 'dateFormat',
  standalone: true,
})
export class DateFormatPipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  transform(value: string | Date, format: string, timeZone: string): string {
    if (!value) return '';

    const adjustedDate = dayjs.utc(value).tz(timeZone);

    // Extract the UTC offset and manually apply it
    const offsetMinutes = adjustedDate.utcOffset();
    const finalDate = new Date(adjustedDate.valueOf() + offsetMinutes * 60 * 1000);

    format = 'EEEE, ' + format; // Add the day of the week
    format = format.replace(/y/g, 'Y'); // Replace 'y' with 'Y' for 4-digit year
    format = format.replace(/[,/\s-]*y[,/\s-]*/i, '').trim(); // remove year

    // Format using Angular's DatePipe
    return this.datePipe.transform(finalDate, format, 'UTC') || '';
  }
}
