import { Injectable } from '@angular/core';
import { AccountService } from './account.service';
import { Account } from '../models/account.model';
import { Expert } from '../models/expert.model';
import { Appointment } from '../models/appointment.model';
import { Subject, lastValueFrom } from 'rxjs';
import { AppointmentItem } from '../models/appointment-item.model';
import { ServiceService } from './service.service';
import { AppointmentIdKey } from '../config/constants';
import { WindowService } from './window.service';
import { SpinService } from './spin.service';
import { ExpertsService } from './experts.service';
import { HttpClient } from '@angular/common/http';
import { SubscribedComponent } from '../lib/subscribed.component';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService extends SubscribedComponent {
  appointmentChanged = new Subject<Appointment>();
  private dataUrl: string = 'appointments/';
  private account?: Account;
  private appointment: Appointment = {} as Appointment;

  constructor(
    private readonly http: HttpClient,
    private accountService: AccountService,
    private expertsService: ExpertsService,
    private serviceService: ServiceService,
    private windowService: WindowService,
    private spinService: SpinService,
  ) {
    super();
    this.account = this.accountService.getAccount();
    this.accountService.accountChanged.pipe().subscribe((account) => {
      this.account = account;
    });
  }

  async initAppointment(isTestMode: boolean): Promise<Appointment> {
    const id = this.appointment.id || +this.windowService.getStorage(AppointmentIdKey(this.account.id));
    if (!id) {
      const defaultAppointment: Partial<Appointment> = {
        locationId: this.account.id,
        test: isTestMode,
      };
      this.appointment = await lastValueFrom(this.http.post<Appointment>(this.dataUrl, defaultAppointment));
      if (!this.appointment.appointmentItems) {
        this.appointment.appointmentItems = [];
      }
      this.windowService.setStorage(AppointmentIdKey(this.account.id), this.appointment.id.toString());
    } else {
      this.appointment = await this.fetchAppointmentFromApi(id);
    }

    this.appointmentChanged.next(this.getCachedAppointment());
    return this.appointment;
  }

  getCachedAppointment(): Appointment {
    return { ...this.appointment };
  }

  async fetchAppointmentFromApi(id: number): Promise<Appointment> {
    return await lastValueFrom(this.http.get<Appointment>(this.dataUrl + id));
  }

  getStandaloneItems(): AppointmentItem[] {
    if (!this.appointment.appointmentItems) return [];
    return this.appointment.appointmentItems.filter((item) => item.parentItemId === null);
  }

  async updateAppointment(appointment: Partial<Appointment>) {
    this.spinService.setCurrentGlobalSpinStore(true);
    const { date, status, firstName, lastName, email, phone, specialRequests } = appointment;
    const appointmentOnly = await lastValueFrom(
      this.http.patch<Appointment>(this.dataUrl + appointment.id, {
        date,
        status,
        firstName,
        lastName,
        email,
        phone,
        specialRequests,
      }),
    );
    this.spinService.setCurrentGlobalSpinStore(false);

    this.appointment = { ...this.appointment, ...appointmentOnly };
    this.appointmentChanged.next({ ...this.appointment });
  }

  // Items
  getItem(serviceItemId: number): AppointmentItem | void {
    for (const item of this.appointment.appointmentItems) {
      if (item.serviceItemId === serviceItemId) {
        return item;
      }
    }
  }

  getAddonsForItem(itemId: number): AppointmentItem[] {
    return this.appointment.appointmentItems.filter((item) => item.parentItemId === itemId);
  }

  getAddonsServiceItemIds(itemId: number): number[] {
    return this.getAddonsForItem(itemId).map((item) => item.serviceItemId);
  }

  getTotalPriceForItem(item: AppointmentItem): number {
    let price = item.choicePrice;

    const addons = this.getAddonsForItem(item.id);
    for (const addon of addons) {
      price += addon.choicePrice;
    }
    return price;
  }

  getTotalDurationForItem(item: AppointmentItem): number {
    let duration = item.choiceDuration;
    const addons = this.getAddonsForItem(item.id);
    for (const addon of addons) {
      duration += addon.choiceDuration;
    }
    return duration;
  }

  async addItem(item: AppointmentItem, addonsServiceItemIds: number[] = []) {
    // TODO move this to a separate AppointmentItem service ?
    const { appointmentId, choiceId, expertId, serviceItemId, startTime } = item;
    this.spinService.setCurrentGlobalSpinStore(true);
    this.appointment = await lastValueFrom(
      this.http.post<Appointment>(this.dataUrl + 'item/', {
        appointmentId,
        choiceId,
        expertId,
        serviceItemId,
        startTime,
        addonsServiceItemIds,
      }),
    );
    this.spinService.setCurrentGlobalSpinStore(false);
    this.appointmentChanged.next(this.getCachedAppointment());
  }

  async updateItem(id: number, item: Partial<AppointmentItem>, addonsServiceItemIds: number[] = undefined) {
    // TODO move this to a separate AppointmentItem service ?
    const { choiceId, expertId, startTime } = item;
    console.log('updateItem', id, item, addonsServiceItemIds, choiceId, expertId, startTime);
    this.spinService.setCurrentGlobalSpinStore(true);
    this.appointment = await lastValueFrom(
      this.http.patch<Appointment>(this.dataUrl + 'item/' + id, {
        choiceId,
        expertId,
        startTime,
        addonsServiceItemIds,
      }),
    );
    this.spinService.setCurrentGlobalSpinStore(false);
    this.appointmentChanged.next(this.getCachedAppointment());
  }

  async deleteItem(orderItem: AppointmentItem) {
    // TODO move this to a separate AppointmentItem service ?
    this.spinService.setCurrentGlobalSpinStore(true);
    this.appointment = await lastValueFrom(this.http.delete<Appointment>(this.dataUrl + 'item/' + orderItem.id));
    this.spinService.setCurrentGlobalSpinStore(false);
    this.appointmentChanged.next(this.getCachedAppointment());
  }

  // Cart
  cartIsEmpty() {
    return this.appointment.appointmentItems && this.appointment.appointmentItems.length === 0;
  }

  // experts
  getExpertsForAppointment(): Expert[] {
    let experts: Expert[] = [];

    for (const item of this.appointment.appointmentItems) {
      experts.push(...this.getExpertsForAppointmentItem(item.serviceItemId, true));
    }

    // remove duplicates
    experts = Array.from(new Set(experts.map((expert) => expert.id))).map((id) =>
      experts.find((expert) => expert.id === id),
    );

    return experts;
  }

  getExpertsForAppointmentItem(serviceItemId: number, returnAll: boolean): Expert[] {
    const item = this.appointment.appointmentItems.find((item) => item.serviceItemId === serviceItemId);
    const expertId = item.expertId;

    if (returnAll || !expertId) {
      const experts = this.serviceService.getExpertsForService(this.serviceService.getService(serviceItemId), false);
      return experts;
    } else {
      return [this.expertsService.getExpertDetails(expertId)];
    }
  }
}
