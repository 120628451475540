import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TimeFormat } from '../models/account.model';

@Pipe({
  name: 'timeFormat',
  standalone: true,
})
export class TimeFormatPipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  transform(time: Date | string, timeFormat: TimeFormat, timeZone?: string): string {
    if (!time) return '';
    let date: Date;

    if (typeof time === 'string') {
      if (time.includes('T')) {
        // ISO string
        date = new Date(time);
      } else {
        // Time-only string (hh:mm)
        const [hours, minutes] = time.split(':').map(Number);
        date = new Date(2000, 0, 1, hours, minutes);
      }
    } else {
      date = time;
    }

    if (isNaN(date.getTime())) {
      console.error('Invalid Date:', time);
      return '';
    }

    // Use Intl.DateTimeFormat if a timeZone is provided
    if (timeZone) {
      const options: Intl.DateTimeFormatOptions = {
        timeZone,
        hour: 'numeric',
        minute: 'numeric',
        hour12: timeFormat.includes('a'),
      };
      return new Intl.DateTimeFormat(this.locale, options).format(date);
    }

    // Fallback to Angular DatePipe if no timeZone
    return this.datePipe.transform(date, timeFormat, this.locale) || '';
  }
}
