import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Account } from '../models/account.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GetAccountPayload } from './account.types';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private dataUrl: string = 'locations/details/';
  protected account: Account = {} as Account;
  private uuid: string;
  accountChanged = new Subject<Account>();

  constructor(
    private readonly http: HttpClient,
    private router: Router,
  ) {}

  getAccount(): Account {
    return this.account;
  }

  getCurrency(): string {
    return this.account.currency;
  }

  fetchAccount(locationUuid: string): Observable<Account | undefined> {
    return this.http.get<GetAccountPayload>(this.dataUrl + locationUuid).pipe(
      map((payload) => {
        const account: Account = {
          ...payload.location,
          paymentGateways: payload.paymentGateways,
        };

        this.account = account;
        this.accountChanged.next(this.account);

        return account;
      }),
      catchError(() => {
        this.router.navigate(['/page-not-found']);
        return of(undefined);
      }),
    );
  }
}
